// init base functions
$(window).on('load', function () {
    classOnBody($('main').attr('id'));
    scrollDirection();
    initGettersAndSetters();
    changeTouchClickText();
    if ($('.s-depoimentos').length) {
        var swiper__depoimentos = new Swiper('.swiper__depoimentos', {
            speed: 400,
            slidesPerView: 1,
            loop: true,
            grabCursor: true,
            autoHeight: true,
            spaceBetween: 0,
            autoplay: {
                delay: 8000,
            },
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
                renderBullet: function (index, className) {
                    return '<span class="' + className + '">' + '0' + (index + 1) + '</span>';
                },
            },
        });

        swiper__depoimentos.on('slideChange', function () {
            swiper__depoimentos.updateAutoHeight(500)
        });
    }
});
$(window).on('hashchange', function () {
    initGettersAndSetters();
});

// call modules to allpages
$.getScript("modules/nav/module_nav.js");
$.getScript("modules/ieDetect/module_ieDetect.js");

// general input masks
if (is.not.ie()) {
    $('.cepMask').mask("99999-999");
    $('.cpfMask').mask("999.999.999-99");
    $('.dataMask').mask("99/99/9999");
    $('.foneMask').focusout(function () {
        var phone, element;
        element = $(this);
        element.unmask();
        phone = element.val().replace(/\D/g, '');
        if (phone.length > 10) {
            element.mask("(99) 99999-999?9");
        } else {
            element.mask("(99) 9999-9999?9");
        }
    }).trigger('focusout');
}

// init AOS
if (is.not.ie()) {
    AOS.init();
} else {
    $('*').removeAttr("data-aos");
}

// components
if ($('.liSelect').length) {
    $('.liSelect select').on('focusin', function () {
        $(this).parent('.liSelect').addClass('is-active');
    });
    $('.liSelect select').on('focusout', function () {
        $(this).parent('.liSelect').removeClass('is-active');
    });
}

// sections
if ($('.s-solicitarCotacao').length) {
    $('.s-solicitarCotacao .c-linkWithArrow').on('click', function() {
        $('.solicitarCotacao__secondStep').addClass('is-active');
        goToSection__scroll($('.s-solicitarCotacao'), 100, 30, 700, 10);
    });
}


$(document).on('change','#formContato_estado',function(){
    var uf = $(this).val();
    $("#formContato_cidade").html('<option>Carregando cidades...</option>');
    $.ajax({
        type: "POST",
        data: {uf: uf},
        url: "ajax-cidades.php",
        success: function (result) {
            $("#formContato_cidade").html(result);
        }
    });
});

$(document).on('change','#formSolicitacaoCotacao_origem_estado',function(){
    var uf = $(this).val();
    $("#formSolicitacaoCotacao_origem_cidade").html('<option>Carregando cidades...</option>');
    $.ajax({
        type: "POST",
        data: {uf: uf},
        url: "ajax-cidades.php",
        success: function (result) {
            $("#formSolicitacaoCotacao_origem_cidade").html(result);
        }
    });
});

$(document).on('change','#formSolicitacaoCotacao_destino_estado',function(){
    var uf = $(this).val();
    $("#formSolicitacaoCotacao_destino_cidade").html('<option>Carregando cidades...</option>');
    $.ajax({
        type: "POST",
        data: {uf: uf},
        url: "ajax-cidades.php",
        success: function (result) {
            $("#formSolicitacaoCotacao_destino_cidade").html(result);
        }
    });
});

if ($('#formCotacao').length) {
    $("#formCotacao").validate({
        submitHandler: function (form) {
            //SUBMIT form
            $(form).ajaxSubmit({
                //target: 'status-envio',
                beforeSubmit: function () {
                    $('#formCotacao .c-stsSend').removeClass('u-dnone');
                    $('#formCotacao .c-stsSend__feedback--load').removeClass('u-dnone');
                    $('#formCotacao #btnCotacao').attr('disabled', true);
                },
                success: function (result) {
                    result = result.split('|');
                    if(parseInt(result[1]) == 1){
                        $('#formCotacao .c-stsSend__feedback').addClass('u-dnone');
                        $('#formCotacao .c-stsSend__feedback--success').removeClass('u-dnone');
                        setTimeout(function(){
                            $('#formCotacao #btnCotacao').attr('disabled',false);
                            $("#formCotacao .field").val('');
                            $("#formCotacao textarea").val('').html('');
                            $("#formCotacao .field").removeClass('error');
                            $('#formCotacao .c-stsSend').addClass('u-dnone');
                            $('#formCotacao .c-stsSend__feedback').addClass('u-dnone');
                        },2000);
                    }else{
                        $('#formCotacao .c-stsSend__feedback').addClass('u-dnone');
                        $('#formCotacao .c-stsSend__feedback--error').removeClass('u-dnone');
                        $('#formCotacao .c-stsSend__feedback--error span').html(result[2]);
                        setTimeout(function () {
                            $('#formCotacao .c-stsSend').addClass('u-dnone');
                            $('#formCotacao .c-stsSend__feedback').addClass('u-dnone');
                            $('#formCotacao #btnCotacao').attr('disabled', false);
                        }, 3000);
                    }
                }
            });
        }
    });
}